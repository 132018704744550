<template>
  <Layout>
    <PageHeader :title="title" moda_title="Datos del operador" ref="pageForm" :items="items">
    </PageHeader>
    <ShowDetails :item="dataDetails" :items="betItems" :result="roundResult" :plenoAmount="plenoAmount"
      :jackpots="jackpots" ref="showDetails" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row mt-4">
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.from') }}: </span>
                  <vs-input v-model="filterData.fromDate" type="date"></vs-input>
                </label>
              </div>
              <div class="col-md-3 col-sm-9">
                <label class="d-inline-flex align-items-center w-100">
                  <span class="m-2">{{ $t('filter.until') }}: </span>
                  <vs-input v-model="filterData.toDate" type="date"></vs-input>
                </label>
              </div>
            </div>
            <div class="row mt-4">
              <div class="col-sm-12 col-md-12 d-flex justify-content-start" v-if="userRole !== 'OPERATOR'"
                @change="getGames">
                <label class="d-inline-flex align-items-center" style="width: max-content">
                  {{ $t("filter.gameType") }}:
                </label>
                <select v-model="filterData.gameType" name="client"
                  class="form-select form-select-sm my-2 ms-2 border-0 shadow-lg w-50" placeholder="Seleccione..">
                  <option disabled selected>
                    {{ $t("helpers.select") }}...
                  </option>
                  <option v-for="(item, index) in mapGamesTypes" :key="index" :value="item.value">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.gameType">
                <label class="d-inline-flex align-items-center w-100">
                  JUEGO:
                  <select v-model="filterData.rouletteId" name="client"
                    class="form-select form-select-sm m-2 shadow-lg border-0" placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option value="*">ALL</option>
                    <option v-for="({ _id, name }, index) in roulettes" :key="index" :value="_id">
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="checkAdmins() && filterData.gameType">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.client') }}:
                  <select v-model="filterData.clientId" name="client" @change="getOperatorClient(true)"
                    class="form-select form-select-sm m-2 shadow-lg border-0" placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="({ _id, name }, index) in clients" :key="index" :value="_id">
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="userRole !== 'OPERATOR' && filterData.clientId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.operator') }}:
                  <select v-model="filterData.operatorId" name="client"
                    class="form-select form-select-sm m-2 shadow-lg border-0" placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option v-for="(item, index) in operators" :key="index" :value="item._id">
                      {{ item.name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.rouletteId">
                <label class="d-inline-flex align-items-center w-100">
                  {{ $t('filter.currency') }}:
                  <select v-model="filterData.currency" name="currency"
                    class="form-select form-select-sm m-2 shadow-lg border-0" placeholder="Seleccione..">
                    <option disabled selected>{{ $t('helpers.select') }}...</option>
                    <option value="*">ALL</option>
                    <option v-for="({ _id, name }, index) in currencies" :key="index" :value="_id">
                      {{ name }}
                    </option>
                  </select>
                </label>
              </div>
              <div class="col-sm-12 col-md-4" v-if="filterData.gameType === gamesTypes.ExternalGames">
                <b-col sm="12" md="12">
                  <vs-checkbox v-model="oldTransactions">
                    TRANSACCIONES ANTIGUAS
                  </vs-checkbox>
                </b-col>
              </div>
            </div>
            <div class="d-flex align-items-center mt-2">
              <!-- <div>
                <b-form-radio
                  v-model="downloadSelected"
                  name="some-radios"
                  value="true"
                  class="me-4"
                  ><span class="ms-2 p-0"
                    >Descargar registros actuales</span
                  ></b-form-radio
                >
                <b-form-radio
                  v-model="downloadSelected"
                  name="some-radios"
                  value="false"
                  ><span class="ms-2 p-0"
                    >Descargar todos los registros</span
                  ></b-form-radio
                >
              </div> -->
              <vs-button>
                <downloadexcel :fetch="exportData" :fields="json_fields" :name="fileName">
                  {{ $t('filter.downloadExcel') }}
                </downloadexcel>
              </vs-button>

              <DateRange @handleRadio="handleRadio" />
              <vs-button type="button" @click="filtrar()"> {{ $t('filter.filter')
                }}</vs-button>
              <vs-button type="button" success @click="cancelFilter()" :disabled="!Object.entries(filterData).length">
                {{ $t('filter.cancel') }}
              </vs-button>
            </div>
            <div class="text-center" v-if="fetching">
              <b-spinner type="grow" label="Spinning"></b-spinner>
              {{ $t('helpers.loading') }}...
            </div>
            <div class="row mt-2" v-if="tableData.length">
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_length" class="dataTables_length">
                  <label class="d-inline-flex align-items-center">
                    {{ $t('filter.show') }}&nbsp;
                    <vs-select v-model="perPage" color="dark" class="shadow-lg">
                      <vs-option v-for="(op, index) in pageOptions" :key="index" :label="op" :value="op">{{ op
                        }}</vs-option>
                    </vs-select>
                    &nbsp;{{ $t('filter.entries') }}
                  </label>
                </div>
              </div>
              <!-- Search -->
              <div class="col-sm-12 col-md-6">
                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                  <label class="d-inline-flex align-items-center m-2">
                    {{ $t('filter.search') }}:
                    <vs-input v-model="filter" type="search" primary class="shadow-lg"></vs-input>
                  </label>
                </div>
              </div>
              <!-- End search -->
              <!-- <TotalRegister :totalRegister="totalRegister" /> -->
            </div>
            <!-- Table -->
            <b-overlay :show="loading" rounded="lg">
              <div class="table-responsive mb-0" v-if="tableData.length">
                <b-table :items="tableData" :fields="fields" responsive="sm" :filter="filter"
                  :filter-included-fields="filterOn" @filtered="onFiltered" :sort-by="sortBy" :sort-desc="sortDesc">
                  <template #cell(totalBet)="data">
                    {{
                    data.item.type === "debit" ? formatNumber(data.item.amount) : 0,
                    }}
                  </template>
                  <template #cell(totalWin)="data">
                    {{
                    data.item.type === "credit" ? formatNumber(data.item.amount) : 0,

                    }}
                  </template>
                  <template #cell(round)="data">
                    {{
                    data.item.round
                    ? data.item.round.code
                    : "No round"
                    }}
                  </template>
                  <template #cell(identifierNumber)="data">
                    {{
                    data.item.round
                    ? data.item.round.identifierNumber
                    : "No round"
                    }}
                  </template>
                  <template #cell(createdAt)="data">
                    <div class="createdAt" @mouseover="handleOver" @mouseleave="handleLeave">
                      {{
                      data.item.createdAt &&
                      moment
                      (data.item.createdAt)
                      .format("DD/MM/YYYY | HH:mm:ss")
                      }}
                      <span class="badge badge-time bg-primary rounded-pill">Server time:
                        {{
  data.item.createdAt &&
                        moment.utc(data.item.createdAt).format(
                        "DD/MM/YYYY | HH:mm:ss"
                        )
                        }}</span>
                    </div>
                  </template>
                  <template #cell(rateUSD)="data">
                    {{
                    data.item.currencyExchangeDollar
                    ? data.item.currencyExchangeDollar
                    : "NO EXCHANGE"
                    }}
                  </template>
                  <template #cell(betUSD)="data">
                    {{
                    data.item.type === "debit"
                    ? data.item.amountExchangeDollar
                    ? formatNumber(data.item.amountExchangeDollar)
                    : 1
                    : 0
                    }}
                  </template>
                  <template #cell(winUSD)="data">
                    {{
                    data.item.type === "credit"
                    ? data.item.amountExchangeDollar
                    ? formatNumber(data.item.amountExchangeDollar)
                    : 1
                    : 0
                    }}
                  </template>
                  <template #cell(details)="data">
                    <div>
                      <vs-button @click="openDetails(data.item)">{{ $t('helpers.look') }}</vs-button>
                    </div>
                  </template>
                </b-table>
                <div v-if="!tableData.length" class="text-center">
                  {{ $t('tableReports.noResults') }}
                </div>
              </div>
            </b-overlay>
            <p class="text-center my-2" v-if="searchActive && !tableData.length && !loading">{{
              $t('tableReports.noResults') }}</p>
            <div class="row" v-if="tableData.length">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <!-- <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination> -->
                    <button class="btn btn-secondary mx-2 uil-angle-left" @click="beforePage"
                      :disabled="!skip"></button>
                    <button class="btn btn-primary mx-2 uil-angle-right" @click="nextPage"
                      :disabled="tableData.length < limit"></button>
                  </ul>
                  <!-- <pagination
                    :totalPages="totalPages"
                    :currentPage="currentPage"
                  ></pagination> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header-simple";
import downloadexcel from "vue-json-excel";
import Swal from "sweetalert2";
import appConfig from "@/app.config";
import ShowDetails from "./show.vue";
import { breadCrumbData } from "../../../../helpers/breadcrumbs/breadCrumbs";
import moment from "moment";
import DateRange from "../components/DateRange.vue";
import { onlyAdmins } from "../../../../router/guards/roles";
import { mapActions, mapGetters } from "vuex";

// import TotalRegister from "../../../../components/totalRegister.vue";
// import pagination from "../../../../components/pagination.vue";

/**
 * Users-table component
 */
export default {
  page: {
    title: "Operators Table",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    ShowDetails,
    downloadexcel,
    DateRange
  },
  data() {
    return {
      //
      oldTransactions: false,
      searchActive: false,
      fetching: false,
      // 
      betItems: [],
      bets: [],
      skip: 0,
      limit: 10,
      fileName: "Historial.xls",
      json_fields: {
        "Round N°. ID": "identifierNumber",
        "Ronda ID": "roundId",
        Ruleta: "rouletteName",
        Cliente: "clientName",
        Operador: "operatorName",
        "Usuario ID": "userId",
        Usuario: "userName",
        Platform: "userAgent",
        Moneda: "currency",
        "Total bet": "totalBet",
        "total ganancia": "totalWin",
        rateUSD: "rateUSD",
        betUSD: "betUSD",
        winUSD: "winUSD",
        start_date: "start_date",
        end_date: "end_date",
      },
      tableData: [],
      totalRegister: 0,
      // totalPages: "...",

      title: this.$t('sidebar.reports.subItems.historial'),
      items: breadCrumbData.userHistory,
      loading: false,
      filterData: {},
      dataDetails: {},
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["round"],
      clients: [],
      operators: [],
      roulettes: [],
      currencies: [],
      sortBy: "end_date",
      sortDesc: true,
      fields: [],
      typeform: {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      },
      clientFilter: null,
      // disabledNextPage: null,
      // disabledPreviousPage: null,
      roundResult: 0,
      jackpots: [],
      plenoAmount: 0,
      userRole: "",
      userData: "",
      gameType: null,
      // ROLES
      readAllRoles: onlyAdmins,

    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
    ...mapGetters({
      mapHistory: "reports/getData",
      formatNumber: 'reports/formatNumbers',
      mapGamesTypes: 'reports/getGamesTypes',
      mapGames: "games/getGames",
      gamesTypes: 'reports/gamesTypes',
      mapFields: "reports/getHistoryFields",
      mapCurrencies: "currencies/getCurrencies",
    })
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;

    this.getUserRole();
    this.getCurrencies();
  },
  methods: {
    checkAdmins() {
      return this.readAllRoles.includes(this.userRole);
    },
    getUserRole() {
      this.userData = this.$store.getters["user/getUserData"];
      this.userRole = this.userData.role.name;

      if (this.userRole === "CLIENT") {
        this.filterData.clientId = this.userData.client;
        this.getOperatorClient();
        this.getRoulettesClient();
      } else if (this.userRole === "OPERATOR") {
        this.filterData.operatorId = this.userData.operator;
        this.getRoulettesOperator();
      } else {
        this.getClients();

        this.getOperator();
        this.getRoulettes();
      }
      // this.getData();
    },
    getOperatorClient(filter) {
      const clientId = filter ? this.filterData.clientId : this.userData.client;
      this.$http
        .get(`/operators?client=${clientId}`)
        .then(({ data }) => {
          if (data.ok) {
            this.operators = data.operators;
          }
        })
        .catch((err) => {
          console.log("Error al obtener operadores del cliente", err);
        });
    },
    getRoulettesClient() {
      const client = this.userData.client;
      this.$http
        .get(`clients/${client}/roulettes`)
        .then(({ data }) => {
          if (data.clientRoulettes.length) {
            const formatData = data.clientRoulettes.map((element) => {
              return element.roulette;
            });
            return (this.roulettes = formatData);
          }
          this.roulettes = [];
        })
        .catch((err) => {
          console.log("Err", err);
        });
    },
    async getRoulettesOperator() {
      const operatorId = this.userData.operator;

      try {

        const { data } = await this.$http.get(`operators/${operatorId}/roulettes`)

        const formattedData = data.operatorRoullettes.map((element) => {
          return element.roulette;
        });
        this.roulettes = formattedData;

      } catch (err) {
        console.log("Error al obtener ruletas del operador", err);
      }
    },
    handleOver(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "visible";
      }
    },
    handleLeave(e) {
      const element = e.target;
      const badge = element.querySelector(".badge-time");
      if (badge) {
        badge.style.visibility = "hidden";
      }
    },
    exchangeTransform(type, amount, usdExchange) {
      if (type === "debit") {
        if (amount) {
          return (amount / usdExchange).toFixed(2);
        }
        return 0;
      }

      if (type === "credit") {
        if (amount) {
          return (amount / usdExchange).toFixed(2);
        }
        return 0;
      }
    },
    getClients() {
      this.$http
        .get("/clients")
        .then((response) => {
          this.clients = response.data.clients;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async getCurrencies() {
      try {
        await this.fetchCurrencies({ role: this.userData.role.name, user: this.userData });
        this.currencies = this.mapCurrencies;
      } catch (err) {
        console.log(err);
      }
    },
    getOperator() {
      this.$http
        .get("/operators")
        .then((response) => {
          this.operators = response.data.operators;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async filtrar() {

      this.searchActive = true
      this.loading = true;
      this.fields = this.mapFields(this.filterData.gameType)
      console.log('this.fields', this.fields);
      try {
        const options = {
          params: {
            limit: this.limit,
            page: this.currentPage,
            ...this.filterData,
          },
        }

        await this.fetchReports({
          gameType: this.filterData.gameType,
          options,
        });
        console.log('mapHistory', this.mapHistory);
        this.tableData = this.mapHistory;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },
    cancelFilter() {
      this.searchActive = false;
      this.filterData = {};
      this.getUserRole();
    },
    getRoulettes() {
      this.$http
        .get("/roulettes")
        .then((response) => {
          this.roulettes = response.data.roulettes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    /**
     * Search the table data with search input
     */
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getData() {

      this.searchActive = true;


      this.loading = true;
      const { data } = await this.$http.get("/reports/history", {
        params: {
          ...this.filterData,

          limit: this.limit,
          page: this.currentPage,
          // pagination: "true", //INFO: Pagination en true para poder paginar, este valor cambiará para el download excel
        },
      });

      this.tableData = data.result;

      // this.disabledNextPage = data.result.hasNextPage;
      // this.disabledPreviousPage = data.result.hasPrevPage;
      // this.totalRegister = data.result.totalDocs;
      // this.totalPages = data.result.totalPages;
      this.loading = false;
    },
    nextPage() {
      this.skip += this.perPage;
      this.currentPage++;
      this.getData();
    },
    beforePage() {
      this.skip -= this.perPage;
      this.currentPage--;

      this.getData();
    },
    daysInMonth(month, year) {
      return new Date(year, month + 1, 0).getDate();
    },
    async handleRadio(value) {
      this.picked = value;
      switch (this.picked) {
        case this.$t('helpers.onPage'):
          break;
        case this.$t('helpers.today'): {
          const initialDate = new Date().toJSON().split("T")[0]
          this.filterData = { ...this.filterData, fromDate: initialDate, toDate: initialDate };
          break;
        }
        // case this.$t('helpers.week'): {
        //   console.log('DESCARGAR', this.$t('helpers.week'));
        //   const currentDay = new Date().getDate();
        //   const dayPos = new Date().getDay();

        //   let from;
        //   let til;

        //   if (currentDay < 7) {
        //     return console.log('Buscar hacia el mes anterior',);
        //   }
        //   if (currentDay > 26) {
        //     return console.log('Buscar hacia el mes siguiente',);
        //   }

        //   from = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${currentDay - dayPos}`).toJSON().split('T')[0];
        //   til = new Date(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${(6 - dayPos) + currentDay}`).toJSON().split('T')[0];

        //   console.log('Buscar en este mes', from, til);


        //   // const month = new Date().getMonth();
        //   // let currentMonthDays = this.daysInMonth(month, new Date().getFullYear());
        //   // const initialDate = new Date().toJSON().split("T")[0]
        //   // console.log('Buscar en', currentMonthDays);
        //   break;
        // }
        case this.$t('helpers.month'): {
          let from = 1;
          let to = this.daysInMonth(new Date().getMonth(), new Date().getFullYear());
          const initialDate = new Date().toJSON().split("T")[0].split("-")
          let fromDate = [initialDate[0], initialDate[1], from].join("-");
          let toDate = [initialDate[0], initialDate[1], to].join("-");
          this.filterData = { ...this.filterData, fromDate, toDate };
          break;
        }
        default:
          break;
      }
      return true;

    },
    async exportData() {

      if (!this.filterData.fromDate || !this.filterData.toDate) return Swal.fire("Es necesario seleccionar un rango de fecha", "", "info")

      this.fetching = true

      const options = {
        params: {
          limit: this.limit,
          page: this.currentPage,
          ...this.filterData,
        },
      }

      await this.fetchReports({ path: "history", type: "result", options });
      if (this.mapHistory.length) {
        const formatterHistory = [];

        this.mapHistory.forEach((element) => {
          formatterHistory.push({
            ...element,
            identifierNumber: element.round
              ? element.round.identifierNumber
              : "No round",
            roundId: element.round
              ? element.round.code
              : "No round",
            clientName: element.client
              ? element.client.name
              : "No client",
            operatorName: element.operator
              ? element.operator.name
              : "No operator",
            userId: element.player
              ? element.player.userId
              : "No ID player",
            userName: element.player
              ? element.player.username
              : "No player",
            rouletteName: element.game
              ? element.game.name
              : "No roulette",
            currency: element.currency
              ? element.currency.short
              : "No currency",
            transactionId: element.bet
              ? element.bet.transactionId
              : "No transaction",
            pleno: element.game
              ? element.game.pleno
              : 0,
            createdAt: moment(element.createdAt).format(
              "DD/MM/YYYY | HH:mm:ss"
            ),
            start_date: moment(element.round.start_date).format(
              "DD/MM/YYYY | HH:mm:ss"
            ),
            end_date: moment(element.round.start_date).format(
              "DD/MM/YYYY | HH:mm:ss"
            ),
            totalBet: element.type === "debit" ? element.amount.toFixed(2) : 0,
            totalWin: element.type === "credit" ? element.amount.toFixed(2) : 0,
            errorRound:
              element.providerresult
                ? element.providerresult
                : 0,
            rollbackRound: element.providerresult
              ? element.providerresult
              : 0,
            retryRound: element.providerresult
              ? element.providerresult
              : 0,
            rateUSD: element.currencyExchangeDollar
              ? element.currencyExchangeDollar
              : "NO EXCHANGE",
            betUSD: element.amountExchangeDollar
              ? element.type === "debit"
                ? String(element.amountExchangeDollar.toFixed(4)).replace(
                  ".",
                  ","
                )
                : 0
              : "NO EXCHANGE",
            winUSD: element.amountExchangeDollar
              ? element.type === "credit"
                ? String(element.amountExchangeDollar.toFixed(4)).replace(
                  ".",
                  ","
                )
                : 0
              : "NO EXCHANGE",
          });
        });

        this.fetching = false

        return formatterHistory;
      } else {
        Swal.fire({
          position: "top-end",
          icon: "danger",
          title: "Advertencia, no se encuentran datos asociados",
          showConfirmButton: false,
          timer: 2000,
        });
      }

      this.fetching = false

    },
    closeModa() {
      this.$refs.pageForm.modal = false;
      this.typeform = {
        name: "",
        minBet: "",
        maxBet: "",
        client: "",
      };
      this.getData();
    },
    openDetails(item) {
      if (!this.$refs.showDetails.modal) {
        this.betItems = [];
        this.jackpots = [];
        this.plenoAmount = 0;
      }

      if (item.bet) {
        this.dataDetails = item;

        this.jackpots = item.round.jackpot_values;
        this.plenoAmount = item.game.pleno;

        this.$http
          .get("/bets/details", {
            params: {
              _id: item.bet._id,
              type: item.type
            },
          })

          .then(({ data }) => {
            this.bets = [];
            const betsFetch = data.bet ? data.bet.bet : [];

            const earnings = data.earnings;
            const earningsBets = earnings.map((earn) => {
              const winnerType = earn.bet.split("/")[0];
              const winnerNumber = earn.bet.split("/")[1];
              return {
                betType: winnerType,
                number: winnerNumber,
                betAmount: earn.amountOriginal,
                earnings: earn.earning,
              };
            });

            for (let bet in betsFetch) {
              if (betsFetch[bet].length) {
                if (bet === "calleNumbers") {
                  const calleNumbers = [];

                  for (let item = 0; item < betsFetch[bet].length; item += 3) {
                    calleNumbers.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      number: [
                        betsFetch[bet][item].number,
                        betsFetch[bet][item].number + 1,
                        betsFetch[bet][item].number + 2,
                      ],
                    });
                  }

                  this.betItems.push(...calleNumbers);
                }

                if (bet === "specialCalle") {
                  const specialCalle = [];

                  for (let item = 0; item < betsFetch[bet].length; item += 3) {
                    specialCalle.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      type: "37-0-1-2-3",
                    });
                  }

                  this.betItems.push(...specialCalle);
                }

                if (bet === "cuadroNumbers") {
                  const cuadroNumbers = [];

                  for (let item = 0; item < betsFetch[bet].length; item += 4) {
                    cuadroNumbers.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      number: [
                        betsFetch[bet][item].number,
                        betsFetch[bet][item].number + 1,
                        betsFetch[bet][item].number + 3,
                        betsFetch[bet][item].number + 4,
                      ],
                    });
                  }

                  this.betItems.push(...cuadroNumbers);
                }

                if (bet === "lineaNumbers") {
                  const lineaNumbers = [];

                  for (let item = 0; item < betsFetch[bet].length; item += 6) {
                    lineaNumbers.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      number: [
                        betsFetch[bet][item].number,
                        betsFetch[bet][item].number + 1,
                        betsFetch[bet][item].number + 2,
                        betsFetch[bet][item].number + 3,
                        betsFetch[bet][item].number + 4,
                        betsFetch[bet][item].number + 5,
                      ],
                    });
                  }

                  this.betItems.push(...lineaNumbers);
                }

                if (bet === "semiPlenoNumbers") {
                  const semiPlenoNumbers = [];
                  for (let item = 0; item < betsFetch[bet].length; item += 2) {
                    const number = betsFetch[bet][item].number;
                    const nextNumber = betsFetch[bet][item + 1].number;

                    semiPlenoNumbers.push({
                      betType: bet,
                      amount: betsFetch[bet][item].amount,
                      number: [number, nextNumber],
                    });
                  }

                  this.betItems.push(...semiPlenoNumbers);
                }

                if (bet === "plenoNumbers") {
                  for (let item = 0; item < betsFetch[bet].length; item++) {
                    this.betItems.push({
                      betType: bet,
                      number: betsFetch[bet][item].number,
                      amount: betsFetch[bet][item].amount,
                    });
                  }
                }

                if (
                  [
                    "chanceSimple",
                    "dozens",
                    "columns",
                    "color",
                    "even_odd",
                    "cubre",
                  ].includes(bet)
                ) {
                  for (let item = 0; item < betsFetch[bet].length; item++) {
                    this.betItems.push({
                      betType: bet,
                      type: betsFetch[bet][item].type,
                      amount: betsFetch[bet][item].amount,
                    });
                  }
                }
              }
            }

            /* const betsWithEarnings =  */ this.betItems.map((bet, index) => {
              if (bet.type) {
                earningsBets.filter((earn) => {
                  if (
                    earn.betType === bet.betType &&
                    earn.number === bet.type &&
                    bet.amount === earn.betAmount
                  ) {
                    this.betItems[index] = {
                      ...this.betItems[index],
                      earnings: earn.earnings,
                    };
                    return earn.earnings;
                  }
                });
              } else {
                earningsBets.filter((earn) => {
                  if (
                    earn.betType === bet.betType &&
                    bet.amount === earn.betAmount
                  ) {
                    if (bet.number instanceof Array) {
                      if (bet.number.includes(Number(earn.number))) {
                        this.betItems[index] = {
                          ...this.betItems[index],
                          earnings: earn.earnings,
                        };
                      }
                    }

                    if (typeof bet.number === "number") {
                      if (bet.number === Number(earn.number)) {
                        this.betItems[index] = {
                          ...this.betItems[index],
                          earnings: earn.earnings,
                        };
                      }
                    }
                    return earn.earnings;
                  }
                });
              }
            });

            this.roundResult = data.roundResult;
          })
          .catch((err) => {
            console.log(err);
          });
      }

      this.$refs.showDetails.modal = true;
    },
    change() {
      this.filtrar();
    },
    async getGames() {

      try {

        if (this.userRole === "CLIENT") {
          this.getRoulettesClient();
        } else if (this.userRole === "OPERATOR") {
          this.getRoulettesOperator();
        } else {
          await this.fetchGames({ gameType: this.filterData.gameType });
          this.roulettes = this.mapGames;
        }

      } catch (error) {
        console.log("ERROR GET GAMES", error);
      }
    },
    ...mapActions({
      fetchReports: "reports/fetchReports",
      fetchGames: "games/fetchGames",
      fetchCurrencies: "currencies/fetchCurrencies",
    })
  },

  watch: {
    perPage() {
      this.skip = 0;
      this.limit = this.perPage;
      this.getData();
    },
  },
  middleware: "authentication",
};
</script>

<style>
.createdAt {
  position: relative;
}

.badge-time {
  position: absolute;
  left: 0;
  top: -12px;
  visibility: hidden;
}

.space-nowrap {
  white-space: nowrap !important;
}
</style>
